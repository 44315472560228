<script>
export default {
  locales: {
    pt: {
      'Password is required.': 'Senha é obrigatório.',
      'Password must be greater than 6 characters.': 'A senha deve ter mais de 6 caracteres.',
      'New password and Confirm password must be identical.': 'As senhas digitas não são iguais.',
      'Current password access entered does not match.': 'O acesso à senha atual inserida não corresponde.',
    },
    es: {
      'Password is required.': 'Se requiere contraseña.',
      'Password must be greater than 6 characters.': 'La contraseña debe tener más de 6 caracteres.',
      'New password and Confirm password must be identical.': 'Nueva contraseña y Confirmar contraseña deben ser idénticos.',
      'Current password access entered does not match.': 'La contraseña de acceso actual ingresada no coincide.',
    }
  },
  props: ['value', 'v'],
  data() {
    return {
      showPassword: false,

      p: this.value
    }
  },
  methods: {
    handleInput () {
      this.$emit('input', this.p);
    }
  },
  watch: {
    value: function (v) {
      this.p = v
    }
  }
};
</script>

<template>
  <div>
    <div class="eye">
      <b-form-input
        @input="handleInput"
        v-model="p"
        :type="(showPassword) ? 'text' : 'password'"
        :class="{ 'is-invalid': v.$error }"
      ></b-form-input>
      <div v-if="v.$error" class="invalid-feedback">
        <span v-if="!v.required">{{ t('Password is required.') }}</span>
        <span v-else-if="!v.minLength">{{ t('Password must be greater than 6 characters.') }}</span>
        <span v-if="v.required && v.minLength && v.sameAs == false">{{ t('New password and Confirm password must be identical.') }}</span>
        <span v-if="v.required && v.minLength && v.isEqual == false">{{ t('Current password access entered does not match.') }}</span>
      </div>
      <a @click="showPassword = !showPassword" class="icon-eye">
        <i :class="(showPassword) ? 'mdi mdi-eye' : 'mdi mdi-eye-off' "></i>
      </a>
    </div>
  </div>
</template>

<style>
  .eye {
    position: relative;
  }

  .eye input {
    width: 100%;
  }

  .eye .is-invalid {
    background-position: right calc(1.7em + 0.235rem) center;
  }

  .icon-eye {
    position:absolute;
    top: 0;
    right: 0;
    left: 20;
    color: #000;
    padding: 10px;
    cursor: pointer;
  }

  .icon-eye:hover {
    color: #000;
  }
</style>